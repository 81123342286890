/*МОДАЛЬНОЕ ОКНО*/
#pop-up__background {
    position: fixed;
    z-index: 20;
    bottom: .7rem;
    left: 5rem;
    //display: none;
    box-shadow: 0 -1px 9px 0 rgba(112,122,133,.53);
}

#pop-up {
    background: #fff;
    width: 27.9rem;
    padding: 2rem;
    position: relative;
    border-radius: .4rem;
}

.close {
    display: block;
    position: absolute;
    top: .9rem;
    right: .9rem;
    cursor: pointer;
}

.pop-up-wrapper {
    h3 {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.75;
        color: #000;
        margin-bottom: .8rem;
    }

    p {
        margin: 0;
        padding: 0;
        font-size: .9rem;
        font-weight: 400;
        line-height: 1.5;
        color: #474747;
        margin-bottom: 1rem;

        span {
            color: #474747;
        }
    }

    button {
        width: 100%;
        background-color: #214099;
        height: 2.5rem;
        border-radius: .2rem;
        color: #fff;
        text-transform: uppercase;
        font-size: .8rem;
        cursor: pointer;
    }
}

.terms-pop-up__background {
    display: none;
}

.terms-pop-up {
    background-color: #fff;
    width: 48rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;

    &__close {
        svg {
            position: absolute;
            right: 2.4rem;
            top: 2.4rem;
            cursor: pointer;
        }
    }

    &__background {
        width: 100%;
        height: 100%;
        background-color: rgba(66, 75, 90, 0.5);
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        //display: none;
        z-index: 101;
    }

    &__title {
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.48;
        text-align: center;
        color: #000;
        margin-bottom: 2.2rem;
    }

    .terms__tabs {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;

        .terms__tab {
            margin-right: 2rem;
            cursor: pointer;

            .terms__input-hide {
                display: none;
            }

            span {
                font-size: .9rem;
                font-weight: 700;
                color: #214099;
                opacity: .6;
                text-transform: uppercase;
                transition: all .3s ease-in-out;
                cursor: pointer;
            }
            
            &.active {
                span {
                    opacity: 1;
                    text-decoration: underline;
                }
            }
        }
    }

    .terms__item {
        display: none;

        &.active {
            display: block;
        }

        .terms__require {
            padding: 0 1rem;
        }

        form {
            display: flex;

            .terms__form-right {
                display: flex;
                flex-direction: column;
                align-items: center;

                > * {
                    width: 100%;
                }
            }

            label {
                display: block;
                padding: 0 1rem;
            }

            textarea,
            select,
            input {
                width: 100%;
                border-radius: 2px;
                border: .1rem solid #c8c9cd;
                padding: .5rem .3rem;
                color: #999b9f;
                margin-bottom: 1rem;
                font-size: .9rem;
                line-height: 1.29;
            }

            .terms__checkbox-wrapper,
            .terms__text {
                padding: 0 1rem;
            }

            .terms__select-wrapper,
            .terms__input-wrapper {
                display: flex;

                input, select {
                    width: 100%;
                }

                label,
                .main-page__label-vehicle,
                .terms__label-year {
                    width: 100%;
                }
            }

            .terms__form-right {
                label {
                    padding: 0 1rem;
                }
                .terms__label-amount {
                    padding: 0;
                }
            }

            .terms__checkbox-wrapper {
                display: flex;

                .main-page__prompt {
                    display: flex;

                    label {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;

                        p {
                            color: #c8c9cd;
                            font-size: .7rem;
                        }

                        input {
                            display: none;
                        }

                        span {
                            position: relative;
                            display: block;
                            width: 18px;
                            height: 18px;
                            margin-right: .5rem;
                            border: .1rem solid #c8c9cd;
                            border-radius: 50%;
                        }

                        input:checked + span {
                            border-color: #214099;

                            &:before {
                                background: #214099;
                                content: '';
                                position: absolute;
                                left: 2px;
                                top: 2px;
                                width: 12px;
                                height: 12px;
                                border-radius: 50%;
                            }

                            & + p {
                                color: #000;
                            }
                        }
                    }
                }
            }

            .terms__button {
                background: #214099;
                width: 17rem;
                height: 2rem;
                font-size: .7rem;
                text-align: center;
                font-weight: 500;
                border-radius: .2rem;
                color: #fff;
                text-transform: uppercase;
                margin: 1.7rem 0 0 0;
                cursor: pointer;
            }

            .error {
                display: block;
                font-size: .7rem;
                color: #999b9f;
                line-height: 1.29;
                font-weight: 400;
            }
        }
    }
}

.page #main-content .et_section_regular {
    #apply-home {
        position: relative;
        top: -1px;
        padding: 1rem;
        background: #fff;

        &, * {
            color: #000;
        }

        .main-page__form-wrapper-form-two h3,
        .main-page__form-wrapper h3 {
            font-size: 1.3rem;
            line-height: 1.48;
            text-align: center;
            color: #000;
            font-weight: 700;
            margin-bottom: .5rem;
            margin-top: 1.5rem;
        }

        .main-page__require {
            display: block;
            font-size: .9rem;
            color: #999b9f;
            line-height: 1.29;
            font-weight: 400;
            margin-bottom: 2.3rem;
        }

        form {
            .main-page__checkbox-wrapper,
            .main-page__select-wrapper,
            .main-page__input-wrapper {
                display: flex;
                margin-bottom: 1rem;

                label {
                    display: block;
                    width: 50%;

                    &:first-of-type {
                        width: 48%;
                        margin-right: 2%;
                    }

                    select,
                    input {
                        width: 100%;
                        margin-bottom: 0;
                    }
                }
            }

            .main-page__label-referred,
            .main-page__label-vehicle,
            .main-page__label-year,
            .main-page__label-city,
            .main-page__label-amount {
                display: block;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background: url(../../build/img/dropdown.svg) right no-repeat;
                    width: 2rem;
                    height: .5rem;
                }
            }

            .main-page__checkbox-wrapper {
                .main-page__prompt {
                    margin-right: 2.5rem;

                    label {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;

                        p {
                            color: #c8c9cd;
                            font-size: .7rem;
                        }

                        input {
                            display: none;
                        }

                        span {
                            position: relative;
                            display: block;
                            width: 1rem;
                            height: 1rem;
                            margin-right: .5rem;
                            border: .1rem solid #c8c9cd;
                            border-radius: 50%;
                        }

                        input:checked + span {
                            border-color: #214099;

                            &:before {
                                background: #214099;
                                content: '';
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%,-50%);
                                width: .6rem;
                                height: .6rem;
                                border-radius: 50%;
                            }
                            
                            & + p {
                                color: #000;
                            }
                        }
                    }
                }
            }

            textarea,
            select,
            input {
                width: 100%;
                border-radius: 2px;
                border: .1rem solid #c8c9cd;
                padding: .5rem .3rem;
                color: #999b9f;
                margin-bottom: 1rem;
                font-size: .9rem;
                line-height: 1.29;
            }

            .main-page__button {
                background: #214099;
                width: 100%;
                height: 2.5rem;
                font-size: .7rem;
                text-align: center;
                font-weight: 500;
                border-radius: .2rem;
                color: #fff;
                text-transform: uppercase;
                margin-top: 1.4rem;
                margin-bottom: 1.4rem;
                cursor: pointer;
            }

            .main-page__text-line {
                position: relative;
                color: #c8c9cd;
                text-transform: uppercase;
                margin-bottom: .9rem;
                text-align: center;

                &:after,
                &:before {
                    background: #c8c9cd;
                    content: '';
                    position: absolute;
                    width: 45%;
                    height: 1px;
                    top: 50%;
                }

                &:before {
                    left: 0;
                }

                &:after {
                    right: 0;
                }
            }

            .main-page__collateral {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                span {
                    font-size: 1.1rem;
                    font-weight: 700;
                    text-align: center;
                    line-height: 1.72;
                    margin-bottom: .4rem;
                    color: #000;
                }

                a {
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.79;
                    text-align: center;
                    text-decoration: underline;
                    color: #214099;
                }
            }

            .error {
                display: block;
                font-size: .7rem;
                color: #999b9f;
                line-height: 1.29;
                font-weight: 400;
            }


        }
    }
}