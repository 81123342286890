.slider {
  margin-bottom: 155px;

  &__title {
    margin-bottom: 17px;

    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      text-transform: uppercase;
    }
  }

  &__container {
    .slider__item {
      position: relative;
      height: auto;
      color: #4F4136;


      .slider__image {
        width: 100%;
        height: 100%;
        flex-grow: 1;
        position: relative;
        padding: 35px 0 0 35px;
        max-width: 376px;

        &:before {
          content: '';
          position: absolute;
          width: calc(100% - 35px);
          height: calc(100% - 35px);
          border: 1px solid rgba(156, 135, 118, 0.15);
          left: 0;
          top: 0;
          z-index: -1;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &__title {
          max-width: 167px;
          width: 100%;
          top: 68px;
          position: absolute;
          height: 56px;
          background: rgba(247, 245, 244, 0.8);
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            padding: 0px 35px;
          }
        }

        &__description {
          width: calc(100% - 35px);
          position: absolute;
          bottom: 0;
          height: 77px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #BCAA9C;
        }
      }
    }
  }

  .swiper-pagination.swiper-pagination-bullets {

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background: rgba(156, 135, 118, 0.21);
      margin: 0 5px;
      border-radius: unset;
      opacity: 1;
      transition: 0.4s background;
    }

    .swiper-pagination-bullet-active {
      background: rgba(156, 135, 118, 0.8);
    }
  }

  &__pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 19px;
    position: relative;
    height: 50px;

    .swiper-pagination {
      position: static;
    }

    .swiper-navigation {
      display: flex;
      position: absolute;
      right: 0;
      top: 0;

      .slider-button:nth-child(1) {
        margin-right: 22px;
      }

      .slider-button {
        width: 50px;
        height: 50px;
        border: 1px solid #9C8776;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
      }
    }
  }
}

.slider-travel {
  margin-bottom: 285px;

  .slider__container {
    .slider__item {
      .slider__image {
        padding: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  #travel-swiper .slider__container .slider__item {
    padding: 90px 60px;
    transition: .3s ease;
    opacity: .5;
  }

  #travel-swiper .slider__container .slider__item.swiper-slide-active {
    padding: 0;
    opacity: 1;
  }
}

.slider__title {
  span {
    color: #9C8776;
  }
}