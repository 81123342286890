.admin-bar {
    &.single-post {
        .blog-article {
            .blog-article__social {
                div.blog-article__social-sticky {
                    top: 40px;
                }
            }
        }
    }
}

div.breadcrumbs {
    margin-top: 2rem;

    ul {
        display: flex;

        li {
            &, * {
                color: #a1aeb7;
            }

            a {
                span {
                    margin-right: .3rem;
                    margin-left: .3rem;
                }
            }

            .breadcrumbs-post {
                color: #424b5a;
            }
        }
    }
}

.page,
.single-post {
    .blog-article {
        display: flex;
        padding-top: 9rem;

        .blog-article__social {
            .blog-article__social-sticky {
                position: sticky;
                top: 0;
                z-index: 3;
            }
        }

        .blog-article__post-wrapper {
            display: flex;
            justify-content: center;
        }
        .blog-article__post {
            width: 80%;

            * {
                color: #000;
                font-size: .95rem;
            }

            p {
                font-weight: 400;
            }

            li {
                display: flex;
                align-items: center;

                &, * {
                    font-weight: 400;
                }

                &:before {
                    content: '';
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    margin-right: .5rem;
                    border-radius: 50%;
                    background: #214099;
                }
            }

            & > h1, h2 {
                padding: 0 0 27px;
                font-size: 2.6rem;
                font-weight: 600;
            }

            .et_pb_section {
                padding: 0;
            }

            .et_pb_text_inner {
                h1, h2, h3, h4, h5, h6 {
                    margin: 0;
                    padding-top: 0;
                    font-size: 2.6rem;
                }
            }
        }
    }
}

.related-posts {
    padding-top: 10rem;
    padding-bottom: 10rem;

    .blog__post-second {
        .blog__card-second {
            width: 23%;
        }
    }
}

.blog.container {
    margin-top: 3rem;

    .blog__wrapper {
        margin-bottom: 3rem;
    }
}

.blog__main-wrapper {
    position: relative;
    display: flex;
    height: 200px;

    &:before {
        position: absolute;
        content: '';
        background: #0a2573;
        background: linear-gradient(90deg,rgba(10,37,115,.85) 25%,rgba(69,116,201,.78) 100%);
        background-position: center;
        width: 100%;
        height: 100%;
    }

    .blog__main.container {
        display: flex;
        justify-content: space-between;

        .blog__main-title {
            h2 {
                font-size: 4.4rem;
                font-weight: 400;
                color: #fff;
            }
        }

        .blog__main-form-wrapper {
            display: flex;

            .blog__main-form-month,
            .blog__main-form {
                div {
                    position: relative;
                }

                select,
                input {
                    background: #fff;
                    width: 20rem;
                    border-radius: 3px;
                    border: 1px solid #c2d1d9;
                    padding: 0 .8rem 0 1rem;
                    height: 2.2rem;
                    display: flex;
                    align-items: center;
                    font-size: .8rem;
                    font-weight: 400;
                    line-height: 1rem;
                    color: #c2d1d9;
                }

                button {
                    position: absolute;
                    right: .5rem;
                    top: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                }
            }

            .blog__main-form-month {
                .blog__label-month {
                    position: relative;
                    display: block;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                        background: url('/wp-content/themes/Divi-Child-Theme/build/img/dropdown.svg') right no-repeat;
                        width: 2rem;
                        height: .5rem;
                    }

                    select {
                        margin-left: 1rem;
                        width: 10rem;
                    }
                }
            }
        }
    }
}

.blog__side-bar {
    width: 23%;

    .blog__side-bar-popular {
        display: flex;
        margin-bottom: 1.3rem;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 2.8rem;
            height: 2.8rem;
            border-radius: 50%;
            background: #424b5a;
            overflow: hidden;
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1.48;
            color: #fff;
            margin-right: .8rem;
        }

        p {
            font-size: .9rem;
            font-weight: 400;
            line-height: 1.5;
            color: #000;
            margin-bottom: .4rem;
        }

        h3 {
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.5;
            color: #000;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

.blog__side-bar-title,
.blog__posts-title {
    position: relative;
    margin-bottom: 3.5rem;

    h2 {
        display: inline;
        position: relative;
        font-size: 1.9rem;
        font-weight: 700;
        line-height: 1.36;
        color: #000;

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            left: 0;
            background: #000;
            height: .15rem;
            bottom: -0.7rem;
            z-index: 1;
        }
    }

    &:after {
        background: #f2f5f7;
        position: absolute;
        content: '';
        width: 100%;
        height: .15rem;
        bottom: -1.1rem;
        left: 0;
    }
}

.blog__post-second,
.blog-article__post-second {
    display: flex;
    flex-wrap: wrap;
}

.blog__posts-wrapper {
    display: flex;
}

.blog-car__card,
.blog__post-first,
.blog__card-second {
    width: 25%;
    margin-bottom: 2.2rem;
    margin-right: 1.2rem;

    .blog-car__card-image,
    .blog__card-second-image {
        height: 11rem;
        margin-bottom: .7rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: .2rem;
        }
    }

    .blog-car__description-date,
    .blog__second-description-date {
        display: block;
        font-size: .9rem;
        font-weight: 400;
        line-height: 1.5;
        color: #a1aeb7;
        margin-bottom: 1rem;
    }

    .blog-car__description-title,
    .blog__second-description-title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 1rem;
        padding-bottom: 0;
        color: #000;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5;
    }

    .blog-car__description-text,
    .blog__second-description-text {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #000;
        margin-bottom: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .blog-car__description-link,
    .blog__second-description-link {
        display: flex;
        align-items: center;
        font-size: .9rem;
        font-weight: 400;
        line-height: 2.21;
        color: #214099;
        text-decoration: underline;

        svg {
            margin-left: .3rem;
        }
    }
}

.blog__posts {
    width: 77%;

    .blog__post-second {
        .blog__card-second {
            width: 30%;
        }
    }

    .blog__post-first {
        width: 100%;
        padding-right: 2.6rem;

        .blog-car__card {
            display: flex;

            .blog-car__card-image {
                flex: 1 .9 auto;
                border-radius: .2rem;
                overflow: hidden;
            }

            .blog-car__card-description {
                margin-left: 1.5rem;

                .blog-car__description-date {
                    display: block;
                    font-size: .9rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #a1aeb7;
                    margin-bottom: 1rem;
                }

                .blog-car__description-title {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    margin-bottom: 1rem;
                    padding-bottom: 0;
                    color: #000;
                    font-size: 1.5rem;
                    font-weight: 700;
                    line-height: 1.5;
                }

                .blog-car__description-link {
                    display: flex;
                    align-items: center;
                    font-size: .9rem;
                    font-weight: 400;
                    line-height: 2.21;
                    color: #214099;
                    text-decoration: underline;

                    svg {
                        margin-left: .3rem;
                    }
                }
            }
        }
    }
}

.blog__show-more {
    background: #214099;
    width: 15rem;
    height: 2.5rem;
    border-radius: .4rem;
    color: #fff;
    text-transform: uppercase;
    font-size: .7rem;
    cursor: pointer;
    margin: 0 auto 3rem;
    display: block;
}


.blog__post-first {
    .blog-car__card {
        width: 100%;

        .blog-car__card-image {
            width: 100%;
            height: 20rem;
            flex: 1 .8 auto;
        }
    }
}

.blog-car__card {
    display: flex;
    width: 100%;

    .blog-car__card-image {
        min-width: 300px;
        width: 300px;
        height: 300px;
        margin-right: 2rem;
    }
}

.blog-car__title {
    margin-top: 3rem;
    margin-bottom: 2rem;

    h2 {
        text-align: center;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.36;
        color: #000;
    }
}