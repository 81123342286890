/*@import "blog.scss";
@import "testimonials.scss";
@import "modals.scss";*/

.et-tb-has-header {
    header {
        box-shadow: 1px 2px 6px 0 rgba(153,155,159,.31);

        .et_pb_section {
            height: 61.5px;
            padding: 0;
        }

        .et_pb_row--with-menu {
            padding: 0;
        }

        .header__first__part {
            display: flex;
            align-items: center;
            height: 61.5px;

            .et_pb_image {
                margin-bottom: 0;
            }
        }

        .header__phones {
            display: flex;
            align-items: center;
            height: 61.5px;

            .header__phone {
                display: flex;
                align-items: center;
                margin-bottom: 0;
                font-size: .75rem;
                font-weight: 400;
                color: #214099;
                margin-right: .5rem;

                &:before {
                    content: url("/wp-content/themes/Divi-Child-Theme/build/img/phone.svg");
                    height: 17px;
                    width: 17px;
                    margin-right: .8rem;
                }
            }
        }
    }

    footer {
        .et_pb_section {
            padding: 0;
        }

        .et_pb_column_1_tb_footer,
        .et_pb_column_2_tb_footer,
        .et_pb_column_3_tb_footer {
            &:after {
                background: #c2d1d9;
                position: absolute;
                content: '';
                right: -1.25rem;
                top: 0;
                width: 1px;
                height: 12.5rem
            }
        }

        .et_pb_column_0_tb_footer {
            margin-right: 0;

            .et_pb_image_wrap {
                max-width: 149px;
                height: 59px;
                max-height: 59px;

                img {
                    max-height: 100%;
                    width: auto;
                }
            }
        }

        .et_pb_column_1_tb_footer {
            width: 20%;
            margin-right: 6.4%;
            padding-top: 8%;

            .et_pb_text {
                margin-bottom: 0;

                .et_pb_text_inner {
                    p {
                        font-size: .6rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #999b9f;
                    }
                }
            }

            a.et_pb_button {
                font-size: .6rem;
                font-weight: 400;
                line-height: 1.5;
                color: #214099;
                padding-left: 0;

                &:hover {
                    background: none;

                    &:after {
                        display: none;
                    }
                }
            }
        }

        .et_pb_column_2_tb_footer,
        .et_pb_column_3_tb_footer,
        .et_pb_column_4_tb_footer {
            .et_pb_text {
                margin-bottom: 1rem;
                color: #000;
                font-weight: 400;
                text-transform: uppercase;

                .et_pb_text_inner {
                    * {

                    }
                }
            }

            .et_pb_menu__wrap {
                justify-content: flex-start;

                .et-menu {
                    display: flex;
                    flex-direction: column;

                }
            }
        }

        .et_pb_row_1_tb_footer {
            padding: 0;

            &:before {
                height: 1px;
            }

            .et_pb_column_5_tb_footer {
                margin-right: 0;
            }

            a.et_pb_button {
                padding: 0;
                border: none;
                color: #000;
                font-size: .8rem;
                font-weight: 400;

                &:hover {
                    &:after {
                        display: none;
                    }
                }
            }

            .et_pb_column_7_tb_footer {
                display: flex;
                justify-content: flex-end;
                width: 63%;
            }
        }

        .et_pb_code {
            .footer_credentials {
                padding-left: 0;
                list-style: none;

                li {
                    font-size: .8rem;

                    a {
                        margin-left: .3rem;
                        color: #214099;
                    }
                }
            }
        }
    }
}

.page {
    //background: #f2f5f7;
    background: #fff;

    &.parent-pageid-414,
    &.page-id-328, &.post-328,
    &.page-id-325, &.post-325 {
        background: #fff;
    }

    .et-menu {
        .sub-menu {
            border-top: none;

            .menu-item {
                a {
                    padding: .3rem 0;
                    font-size: .8rem;

                    &:hover {
                        background-color: transparent;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    #menu-main-menu {
        .menu-item {
            text-transform: uppercase;
            font-weight: 400;
            font-size: 0.75rem;
        }

        .current-menu-item {
            a {
                font-weight: 500 !important;
                color: #214099 !important;
            }
        }
    }

    #main-content {
        .et_pb_fullwidth_post_title_0,
        .et_pb_fullwidth_header {
            position: relative;
            padding: 0;

            &:before {
                position: absolute;
                content: '';
                background: linear-gradient(90deg,rgba(10,37,115,.85) 25%,rgba(69,116,201,.78) 100%);
                width: 100%;
                height: 100%;
            }

            .et_pb_title_container,
            .et_pb_fullwidth_header_container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                max-width: 71.3rem;

                h1 {
                    width: 48.6%;
                    margin-bottom: 1.6rem;
                    padding: 0;
                    font-size: 4.1rem;
                    font-weight: 400;
                    color: #fff;
                }

                .et_pb_fullwidth_header_subhead {
                    width: 40.8%;
                    font-size: 1rem;
                    line-height: 1.5;
                    margin-bottom: 1.6rem;
                    font-weight: 400;
                }

                .et_pb_button_one,
                .et_pb_button_two {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 12.5rem;
                    height: 2.5rem;
                    border: none;
                    font-size: .96rem;
                    line-height: 1.86;
                    cursor: pointer;

                    &:hover {
                        padding: 0;
                        background: none;

                        &:after {
                            display: none;
                        }
                    }
                }

                .et_pb_button_one {
                    margin: 0 3rem 0 0;
                    float: left;
                    background: #fff;
                    border-radius: .4rem;
                    color: #214099;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-weight: 500;
                    &:hover {
                        background: #fff;
                    }
                }

                .et_pb_button_two {
                    text-decoration: underline;
                    width: 15rem;
                }
            }
        }

        .et_section_regular {
            .et_pb_text_inner {
                font-size: .95rem;
                font-weight: 400;
                line-height: 1.5;
                color: #000;
                margin-bottom: 3rem;
                padding-top: 1.8rem;

                * {
                    color: #000;
                }
                
                h1 {
                    color: #fff;
                }

                > * {
                    margin-bottom: 1.85rem;
                }

                li {
                    margin-bottom: .85rem;
                }

                h1, h2 {
                    font-size: 2.4rem;
                    line-height: 1.26;
                    text-align: center;
                    padding: 0;
                }

                h3, h4, h5, h6 {
                    font-size: .95rem;
                    font-weight: bold;
                    line-height: 1.5;
                    padding: 0;
                }
            }
        }

        .et_pb_section.loans_for_all_needs {
            padding-top: 2.5rem;
            background: #f2f5f7;

            .et_pb_row:first-of-type {
                padding-top: 0;

                .et_pb_text_inner {
                    margin-bottom: 0;

                    h3, h3 * {
                        font-size: 2.6rem;
                        font-weight: 700;
                        line-height: 1.26;
                        text-align: center;
                        color: #000;
                        margin-bottom: 0;
                    }
                }
            }

            > .et_pb_row > .et_pb_column_1_5 {
                position: relative;
                width: 17.8%;
                height: 170px;
                margin-right: 2.5%;
                background: #fff;
                padding: 2rem 1rem;
                text-align: center;
                box-shadow: 0 4px 8px 0 rgba(0,0,0,.05);

                .et_pb_image {
                    margin-bottom: 0;

                    .et_pb_image_wrap {
                        position: absolute;
                        top: -17%;
                        left: 34%;
                    }
                }

                .et_pb_text {
                    height: 62px;
                    margin-bottom: 0;

                    .et_pb_text_inner {
                        margin-bottom: 0;
                        padding: 0;

                        h4 {
                            &, * {
                                font-size: 1.3rem;
                                font-weight: 700;
                                line-height: 1.48;
                                margin-bottom: 0;
                                color: #000;
                            }
                        }
                    }
                }

                .et_pb_button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: .9rem;
                    font-weight: 400;
                    line-height: 2.21;
                    color: #214099;
                    background: none;
                    text-decoration: underline;

                    &:after {
                        content: url("/wp-content/themes/Divi-Child-Theme/build/img/arrow-forward.svg");
                        opacity: 1;
                        margin-left: .3rem;
                        position: initial;
                    }

                    &:hover {
                        background: none;
                        padding-left: 0;
                        padding-right: 0;

                        &:after {
                            display: block;
                        }
                    }
                }
            }
        }

        .et_pb_section.motorcycle_storage_loan {
            padding: 0;
            overflow: visible;

            .et_pb_row {
                background: linear-gradient(100deg,#173874 13%,#274892 100%);
            }

            .et_pb_image {
                height: 387px
            }

            .et_pb_module,
            .et_pb_text_inner {
                margin-bottom: 0;
            }

            h3 {
                margin-bottom: .5rem;
                color: #fff;
                font-size: 2.4rem;
                font-weight: 700;
            }

            p {
                font-size: .9rem;
                font-weight: 400;
                line-height: 1.5;
                color: #a1aeb7;
                margin-bottom: 1.5rem;
                text-align: center;
            }

            .et_pb_button_0_wrapper {
                display: flex;
                align-items: center;
                justify-content: center;

                .et_pb_button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 10.5rem;
                    height: 2.5rem;
                    padding: 0;
                    border-radius: .4rem;
                    border: none;
                    background: #fff;
                    color: #214099;
                    font-size: .9rem;
                    font-weight: 500;
                    line-height: 1.86;
                    text-decoration: none;
                    text-transform: uppercase;
                    margin-right: 1.5rem;
                    cursor: pointer;

                    &:hover {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        .et_pb_row.loan_advantages {
            display: flex;
            align-items: center;
            justify-content: center;

            > .et_pb_column {
                max-width: 12rem;
                margin: 0;
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;

                .et_pb_text_inner,
                .et_pb_image {
                    display: flex;
                    justify-content: center;
                    margin: 0;
                    padding: 0;
                }

                .et_pb_image {
                    margin-bottom: 1rem;
                }

                .et_pb_text_inner {
                    color: #505d68;

                    p {
                        margin: 0;
                        padding: 0;

                        &, * {
                            color: #505d68;
                        }
                    }
                }
            }
        }

        .offices_in_other_cities {
            & + .get_cash_today {
                &:before {
                    background: #f2f5f7;
                }
            }

            .et_pb_row:first-of-type {
                h3 {
                    &, * {
                        font-size: 2.6rem;
                        font-weight: 700;
                        line-height: 1.48;
                        color: #000;
                    }
                }
            }

            .et_pb_row:not(:first-of-type) {
                padding-top: 0;
                padding-bottom: 0;

                > .et_pb_column {
                    width: 31%;
                    margin-bottom: 1.5rem;
                    margin-right: 1.5rem;
                    padding: 1.4rem 1.8rem;
                    border-radius: .2rem;
                    box-shadow: 0 4px 8px 0 rgba(0,0,0,.05);
                    background: #fff;

                    &.et-last-child {
                        margin-right: 0;
                    }

                    > .et_pb_module,
                    > .et_pb_module * {
                        margin: 0;
                        padding: 0;
                    }

                    .et_pb_text_inner {
                        h4 {
                            margin-bottom: 1.4rem;
                            font-size: 1.3rem;
                            font-weight: 700;
                            line-height: 1.48;
                            color: #000;
                        }
                    }
                    
                    .et_pb_module:nth-of-type(2) {
                        display: flex;
                        align-items: center;
                        margin-bottom: 1.4rem;
                        font-size: 1.2rem;
                        text-decoration: underline;

                        &:before {
                            content: '';
                            display: inline-block;
                            width: 14px;
                            height: 20px;
                            margin-right: 1.25rem;
                            background-image: url("/wp-content/themes/Divi-Child-Theme/assets/images/map.svg");
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }

                    .et_pb_module:nth-of-type(3) {
                        display: flex;
                        align-items: center;
                        font-size: 1.2rem;

                        &:before {
                            content: '';
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            margin-right: 1.25rem;
                            background-image: url("/wp-content/themes/Divi-Child-Theme/assets/images/phone-grey.svg");
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }
                }
            }
        }

        .et_pb_section.get_cash_today {
            position: relative;
            height: 275px;
            margin-top: 10rem;
            padding: 0;
            background-image: linear-gradient(99deg,#173874 13%,#274892 100%);

            &:before {
                content: '';
                position: absolute;
                top: -10rem;
                height: 10rem;
                width: 100%;
                display: block;
            }

            .et_pb_text_inner {
                margin-bottom: 0;
                padding-top: 1.3rem;

                h2 {
                    margin-bottom: 1.1rem;
                    text-align: left;
                    color: #fff;
                }

                p {
                    font-size: .9rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #a1aeb7;
                    margin-bottom: 1.2rem;
                    width: 65%;
                }
            }

            .et_pb_button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 10.5rem;
                height: 2.5rem;
                padding: 0;
                border-radius: .4rem;
                border: none;
                background: #fff;
                color: #214099;
                font-size: .9rem;
                font-weight: 500;
                line-height: 1.86;
                text-decoration: none;
                text-transform: uppercase;
                margin-right: 1.5rem;
                cursor: pointer;

                &:hover {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .et_pb_contact_form_container {
            input,
            textarea {
                width: 100%;
                padding: .6rem 2rem;
                border-radius: .2rem;
                border: 1px solid #c8c9cd;
                font-size: .9rem;
                font-weight: 400;
                line-height: 1.29;
                color: #999b9f;
                background: #fff;

                &::placeholder {
                    color: #999b9f;
                }
            }

            .et_pb_contact_field {
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 12px;
                    left: 25px;
                    display: block;
                    height: 18px;
                    width: 18px;
                    z-index: 1;
                    background-size: contain;
                }

                &[data-id="name"] {
                    &:before {
                        background-image: url("/wp-content/themes/Divi-Child-Theme/assets/images/user-line.svg");
                    }
                }

                &[data-id="phone"] {
                    &:before {
                        background-image: url("/wp-content/themes/Divi-Child-Theme/assets/images/phoneCon.svg");
                    }
                }

                &[data-id="email"] {
                    &:before {
                        background-image: url("/wp-content/themes/Divi-Child-Theme/assets/images/mailform.svg");
                    }
                }
            }

            textarea {
                padding-left: .7rem;
            }

            .et_pb_contact_field_checkbox label {
                user-select: none;

                i {
                    border-radius: .1rem;
                    border: 1px solid #c8c9cd;
                    background: #fff;

                    &:before {
                        content: "";
                        background: #214099;
                        width: 12px;
                        height: 12px;
                        font-size: 17px;
                        line-height: 17px;
                        border-radius: .1rem;
                    }
                }
            }

            .et_contact_bottom_container {
                width: 100%;
                margin-top: 1rem;

                .et_pb_contact_submit {
                    width: 100%;
                    background: #214099;
                    border: none;
                    color: #fff;
                    font-size: 0.8rem;
                    text-transform: uppercase;
                    font-weight: 400;
                    padding: 0.6rem;
                }
            }
        }


        #hq_contacts {
            h3, h4, h5, h6 {
                &, * {
                    font-size: 2.5rem;
                }
            }

            .et_pb_button_module_wrapper {
                margin-bottom: 1rem;
            }

            a.et_pb_button {
                display: flex;
                align-items: center;
                position: relative;
                margin-left: 2rem;
                padding: 0;
                border: none;
                color: #000;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;

                &:hover {
                    background: none;

                    &:after {
                        display: none;
                    }
                }

                &:before {
                    content: '';
                    position: initial;
                    margin-right: 1rem;
                    display: block;
                    opacity: 1;
                    height: 24px;
                    width: 24px;
                    margin-top: .1rem;
                    z-index: 1;
                    background-size: contain;
                }

                &.hq_phone {
                    &:before {
                        background-image: url("/wp-content/themes/Divi-Child-Theme/assets/images/phone-grey.svg");
                    }
                }

                &.hq_fax {
                    &:before {
                        background-image: url("/wp-content/themes/Divi-Child-Theme/assets/images/fax-grey.svg");
                    }
                }

                &.hq_mail {
                    &:before {
                        background-image: url("/wp-content/themes/Divi-Child-Theme/assets/images/mail-inverse-grey.svg");
                    }
                }

                &.hq_address {
                    &:before {
                        background-image: url("/wp-content/themes/Divi-Child-Theme/assets/images/map.svg");
                    }
                }
            }
        }

        #contact_form {
            h3, h4, h5, h6 {
                &, * {
                    font-size: 2.5rem;
                }
            }

            .et_pb_button {
                &:hover {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .faq_section {
            padding-bottom: 1rem;
            background: #f2f5f7;

            .et_pb_accordion_item {
                margin-bottom: .5rem;
                border: none;
                background: #fff;
                box-shadow: 0 4px 8px 0 rgba(0,0,0,.05);

                .et_pb_toggle_title {
                    padding: .4rem .5rem;
                    color: #505d68;
                    font-size: 1.3rem;
                    font-weight: bold;

                    &:before {
                        content: '';
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 32px;
                        height: 32px;
                        background-color: #214099;
                        background-image: url('/wp-content/themes/Divi-Child-Theme/assets/images/close.svg');
                        background-size: 50%;
                        background-repeat: no-repeat;
                        background-position: center;
                        color: #fff;
                        border-radius: 3px;
                        font-size: 2rem;
                        font-family: initial !important;
                    }
                }

                &.et_pb_toggle_open {
                    .et_pb_toggle_title {
                        &:before {
                            display: none;
                            //background-image: url('/wp-content/themes/Divi-Child-Theme/assets/images/open.svg');
                        }
                    }
                }
            }
        }

        .privacy_policy_page,
        .terms_page {
            .et_pb_fullwidth_header {
                .et_pb_fullwidth_header_container {
                    h1 {
                        width: 100%;
                    }
                }
            }

            &.et_section_regular {
                .et_pb_text_inner {
                    h2 {
                        font-size: 1.7rem;
                        font-weight: bold;
                    }

                    h3, h4, h5, h6 {
                        margin-bottom: .5rem;
                        font-size: 1.1rem;
                    }

                    p {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

.page {
    #main-content {
        .et_pb_row.office_card {
            background: #fff;
            margin: auto;
            height: 100%;
            width: 30%;
            border-radius: .1rem;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,.05);
            padding: 1rem 2rem;
            display: block;
            text-decoration: none;

            .office_name {
                margin-bottom: 1rem;
                padding-bottom: 0;

                &, *, .et_pb_text_inner * {
                    color: #000;
                    font-size: 1.3rem;
                    font-weight: 700;
                    line-height: 1.5;
                    margin-bottom: 0;
                    padding-top: 0;
                }
            }

            .office_address {
                display: flex;
                align-items: center;
                margin-top: 1rem;
                text-decoration: underline;

                &:before {
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin-right: .8rem;
                    background-image: url("/wp-content/themes/Divi-Child-Theme/assets/images/map.svg");
                    background-size: contain;
                }

                &, .et_pb_text_inner, * {
                    margin-bottom: 0;
                    padding: 0;
                }
            }

            .office_phone {
                display: flex;
                align-items: center;
                margin-top: 1rem;

                &:before {
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin-right: .8rem;
                    background-image: url("/wp-content/themes/Divi-Child-Theme/assets/images/phone-grey.svg");
                    background-size: contain;
                }

                &, .et_pb_text_inner, * {
                    margin-bottom: 0;
                    padding: 0;
                }
            }
        }

        .go_to_faq_section.et_section_regular {
            padding: 0;
            background: #214099;

            > .et_pb_row {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                padding: 0;

                > .et_pb_column {
                    .et_pb_text_inner {
                        margin: 0;

                        &, * {
                            color: #fff;
                        }
                    }

                    .et_pb_button_module_wrapper {
                        &, * {
                            border-radius: .2rem;
                            background: #fff;
                            color: #214099;
                            font-size: .7rem;
                        }
                    }
                }
            }
        }
    }
}

.home.page {
    #main-content {
        .et_section_regular {
            padding-top: 0;

            .et_pb_row_0 {
                padding-top: 0;

                &, *, h1.text {
                    color: #fff;
                    text-align: left;
                }

                h1.text {
                    font-size: 4rem;
                }

                .et_pb_column_1 {
                    padding-top: 4.5rem;
                    width: 30%;
                }

                ul {
                    padding-left: 0;

                    li {
                        display: flex;
                        align-items: center;
                        list-style: none;

                        &, * {
                            font-size: 1.2rem;
                            font-weight: 400;
                        }

                        &:before {
                            content: '';
                            display: inline-block;
                            width: 35px;
                            height: 35px;
                            margin-right: .5rem;
                            background-image: url("/wp-content/themes/Divi-Child-Theme/assets/images/check.svg");
                            background-size: contain;
                            background-repeat: no-repeat;
                        }
                    }
                }
            }

            .et_pb_row {
                h3 {
                    &, * {
                        margin-bottom: 0;
                        font-size: 3.4rem;
                    }
                }

                h4 {
                    &, * {
                        margin-bottom: 0;
                        font-size: 1.45rem;
                        line-height: 1.48;
                        font-weight: 400;
                        color: #474747;
                        text-align: center;
                    }
                }

                h5 {
                    &, * {
                        margin-bottom: 1rem;
                        font-size: 1.3rem;
                        line-height: 1.48;
                        color: #000;
                    }
                }

                .et_pb_button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: .5rem 2rem;
                    border: none;
                    background: #214099;
                    
                    &, * {
                        color: #fff;
                        font-size: .9rem;
                    }

                    &:hover {
                        &:after {
                            display: none;
                        }
                    }
                }

                .et_pb_column_6 {
                    .et_pb_button_0_wrapper {
                        float: left;
                        width: max-content;
                        margin: 0;
                    }

                    .et_pb_button_1_wrapper {
                        float: right;
                        width: max-content;

                        .et_pb_button {
                            background: none;
                            color: #214099;
                            text-decoration: underline;

                            &:after {
                                content: '';
                                right: .5rem;
                                display: inline-block;
                                height: 20px;
                                width: 20px;
                                opacity: 1;
                                background-image: url("/wp-content/themes/Divi-Child-Theme/assets/images/arrow-forward.svg");
                            }
                        }
                    }
                }

                .big-number {
                    position: absolute;
                    top: -11rem;

                    &, .et_pb_text_inner * {
                        opacity: .3;
                        color: #999b9f;
                        font-size: 13rem;
                    }
                }

                &.et_pb_row_3 {
                    .et_pb_text_inner {
                        * {
                            margin: 0;
                        }

                        p {
                            font-size: 1.3rem;
                            font-weight: 400;
                            line-height: 1.48;
                            color: #474747;
                            text-align: center;
                        }
                    }
                }

                &.et_pb_row_4 {
                    padding-top: 7rem;

                    .tp_pb_column {
                        position: relative;
                    }

                    .et_pb_image {
                        margin: 1rem 0 .5rem 0;
                        padding: 0;
                    }

                    .et_pb_module {
                        > .et_pb_text_inner {

                        }
                    }

                    .et_pb_text {
                        &, * {
                            margin: 0;
                            padding: 0;
                        }

                        h3 {
                            font-size: 1.1rem;
                        }
                    }
                }

                &.et_pb_row_5 {
                    width: 17rem;
                }

                &.et_pb_row_6,
                &.et_pb_row_7 {
                    width: 100%;
                    margin: 0;
                    max-width: unset;
                    background: #f6f6f6;
                }

                &.et_pb_row_6 {
                    padding-bottom: 0;

                    .et_pb_text_inner > p {
                        margin-bottom: 0;
                        color: #474747;
                        font-size: 1.4rem;
                    }
                }

                &.et_pb_row_8 {
                    .et_pb_text_inner {
                        p {
                            font-size: 1.3rem;
                            font-weight: 400;
                            line-height: 1.48;
                            color: #474747;
                            text-align: center;
                        }
                    }
                }

                &.et_pb_row_9 {
                    .et_pb_text_inner {
                        padding-top: 0;

                        h3 {
                            margin-bottom: .7rem;
                            font-size: 1.2rem;
                            line-height: 1.48;
                        }

                        &, * {
                            margin-bottom: 0;
                        }

                        p {
                            color: #474747;
                        }
                    }

                    .et_pb_button_3_wrapper {
                        float: left;
                        width: max-content;
                        margin: 0;
                    }

                    .et_pb_button_4_wrapper {
                        float: right;
                        width: max-content;

                        .et_pb_button {
                            background: none;
                            color: #214099;
                            text-decoration: underline;

                            &:after {
                                content: '';
                                right: .5rem;
                                display: inline-block;
                                height: 20px;
                                width: 20px;
                                opacity: 1;
                                background-image: url("/wp-content/themes/Divi-Child-Theme/assets/images/arrow-forward.svg");
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 765px) {
    div#pop-up__background {
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;

        #pop-up {
            width: 100%;

            p {
                font-size: 1rem;
            }
        }
    }

    div.terms-pop-up__background {
        top: 0;
        left: 0;
        right: 0;
        transform: unset;
    }

    div#apply-now {
        width: 100%;
        max-height: 100vh;
        margin-bottom: 1rem;
        padding: .5rem;
        overflow: auto;

        form {
            flex-wrap: wrap;

            .terms__form-left,
            .terms__form-right {
                width: 100%;
                padding: 0 1rem;
            }
        }

        &:after {
            content: '';
            display: block;
            height: 2rem;
        }
    }

    .et-tb-has-header {
        header {
            height: 56px;
            padding-left: 1rem;
            padding-right: 1rem;

            .header__first__part {
                justify-content: space-between;
                margin-bottom: 0;
            }
            .header__phones {
                display: none;
            }

            .et_pb_module {
                margin: 0;
            }

            .et_mobile_menu {
                height: 100vh;
                width: 100vw;
                left: 0;
                top: 0;
                position: fixed;
                border-top: none;
                z-index: 100;
            }
        }

        #main-content {
            .et_pb_section_0.et_section_regular.et_pb_fullwidth_section {
                padding: 0;
            }

            .et_pb_post_title,
            .et_pb_fullwidth_header {
                &:before {
                    left: 0;
                }

                .et_pb_title_container,
                .et_pb_fullwidth_header_container {
                    width: 100%;
                    margin: 0;
                    padding: .5rem;
                }

                .et_pb_title_container {
                    justify-content: flex-start;
                    padding-top: 3rem;

                    h1 {
                        width: 100%;

                        &, * {
                            font-size: 1.8rem;
                            line-height: 1.2;
                        }
                    }
                }

                .header-content-container {
                    .header-content {
                        .et_pb_fullwidth_header_subhead,
                        h1 {
                            width: 100%;
                            
                            &, * {
                                font-size: 1.8rem;
                                line-height: 1.2;
                            }
                        }

                        .et_pb_fullwidth_header_subhead {
                            &, * {
                                font-size: 1rem;
                            }
                        }

                        .et_pb_button {
                            display: flex !important;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            margin-right: 0;
                        }
                    }
                }
            }

            .et_section_regular {
                .et_pb_module {
                    &.et_pb_text {
                        margin-bottom: 1rem;
                    }

                    .et_pb_text_inner {
                        margin-bottom: 0;

                        h1.text {
                            &, * {
                                font-size: 1.8rem;
                                line-height: 1.2;
                            }
                        }

                        h3 {
                            &, * {
                                font-size: 1.8rem;
                                line-height: 1.2;
                            }
                        }

                        h4 {
                            margin-top: 1rem;

                            &, * {
                                font-size: 1.1rem;
                                line-height: 1.2;
                            }
                        }

                        ul {
                            li {
                                &, * {
                                    font-size: 1rem;

                                    &:before {
                                        width: 24px;
                                        min-width: 24px;
                                        height: 24px;
                                        min-height: 24px;
                                    }
                                }
                            }
                        }
                    }
                }

                &.et_pb_section_0 {
                    padding-bottom: 1rem;

                    .et_pb_row {
                        padding: 0;

                        .big-number {
                            top: -7rem;

                            .et_pb_text_inner {
                                &, * {
                                    font-size: 10rem;
                                }
                            }
                        }
                    }
                    .et_pb_row_0 {
                        background-position: left;

                        .et_pb_column_1 {
                            padding: 1rem;

                            ul {
                                width: 80%;
                            }
                        }

                        .et_pb_column_3 {
                            display: none;
                        }
                    }

                    .et_pb_row_2 {
                        .et_pb_column {
                            .et_pb_button_0_wrapper {
                                display: none;
                            }

                            .et_pb_button_1_wrapper {
                                float: initial;

                                a {
                                    padding-left: 0;
                                }
                            }
                        }
                    }

                    .et_pb_row_4 {
                        padding-top: 2rem;
                        .et_pb_column {
                            margin-bottom: 7rem;

                            &:last-of-type {
                                margin-bottom: 4rem;
                            }
                        }
                    }

                    .et_pb_row_5 {
                        .et_pb_button_module_wrapper {
                            display: none;
                        }
                    }

                    .et_pb_row.et_pb_row_8 {
                        width: 100%;
                        margin: 0;
                        padding: 1.5rem 1.5rem 0;

                        .et_pb_text_13 {
                            .et_pb_text_inner {
                                p {
                                    margin-top: 1rem;

                                    &, * {
                                        font-size: 1.1rem;
                                    }
                                }
                            }
                        }
                    }

                    .et_pb_row.et_pb_row_9 {
                        display: flex;
                        flex-direction: column-reverse;

                        .et_pb_column {
                            margin-bottom: 1rem;
                        }

                        .et_pb_image_wrap {
                            margin-bottom: 1rem;
                        }

                        .et_pb_button_3_wrapper {
                            display: none;
                        }

                        .et_pb_button_4_wrapper {
                            float: initial;

                            a {
                                padding-top: 0;
                                padding-left: 0;
                            }
                        }
                    }
                }

                &.go_to_faq_section {
                    height: max-content;
                    max-height: max-content;
                    padding-top: 1rem;
                    padding-bottom: 1rem;

                    * {
                        text-align: center;
                    }

                    .et_pb_row {
                        flex-direction: column;
                    }

                    .et_pb_column {
                        margin: 0;
                    }

                    .et_pb_image_wrap {
                        display: flex;
                        justify-content: center;
                    }
                }

                .reviews {
                    .reviews__slider {
                        margin-left: 1rem;

                        .reviews__slide {
                            min-width: 340px;
                        }
                    }
                }
            }

            .et_pb_row.loan_advantages > .et_pb_column {
                width: 50% !important;

                .et_pb_text_inner {
                    p {
                        &, * {
                            font-size: .9rem;
                        }
                    }
                }
            }

            .et_section_regular {
                .et_pb_module.et_pb_text {
                    margin-left: 0 !important;
                    margin-right: 0 !important;

                    .et_pb_text_inner {
                        h2 {
                            &, * {
                                font-size: 1.3rem;
                            }
                        }

                        h3, h4 {
                            &, * {
                                font-size: 1rem;
                            }
                        }
                    }
                }

                &.motorcycle_storage_loan {

                    > .et_pb_row {
                        height: max-content;
                        padding: 1rem;
                    }

                    .et_pb_image {
                        display: none;
                    }

                    .et_pb_column .et_pb_module .et_pb_text_inner {
                        h3 {
                            font-size: 1.8rem;
                        }

                        p {

                        }
                    }

                    .et_pb_button_module_wrapper {
                        width: 100%;

                        .et_pb_button {
                            width: 100%;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}