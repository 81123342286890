.swiper-container {
    height: 300px;
}

.reviews {
    position: relative;
    margin-bottom: 6rem;
    background-color: #f6f6f6;

    &__slide {
        background-color: #fff;
        padding: 3rem;
    }

    &__slider {
        position: relative;
        margin-left: 15rem;
        margin-right: 0;
    }

    &__people-image {
        margin-right: 2.6rem;

        img {
            width: 3.7rem;
            height: 3.7rem;
            border-radius: 50%;
            overflow: hidden;
            object-fit: cover;
            object-position: center;
        }
    }

    .swiper-button-next {
        left: -3.4rem;

        &:focus {
            outline: none;
        }
    }

    .swiper-pagination {
        left: 50%;
        transform: translateX(-50%);
        bottom: -4.5rem;
    }

    .swiper-pagination-bullet {
        width: .6rem;
        height: .6rem;
        margin: 0 .8rem;
        opacity: 1;
        background: none;
        border: 2px solid #999b9f;
    }

    .swiper-pagination-bullet-active {
        background: #214099;
        width: .6rem;
        height: .6rem;
        border: none;
    }

    .swiper-button-next:after {
        content: none;
    }

    &__people-name {
        span {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #000;
        }

        p {
            font-size: .9rem;
            font-weight: 400;
            line-height: 1.5;
            color: #999b9f;
        }
    }

    &__people-review {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #474747;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &__people {
        display: flex;
        align-items: center;
        margin-bottom: 1.3rem;
    }
}